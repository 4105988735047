import React from "react"
import Prose from "../../components/prose"
import Descriptive from "../../blogs/descriptive_stats"
import TransitionModel from  "../../components/transition_model"
//import GraphicalModel from "../../components/graphical-model"

export default () => (
  <div>
    <Prose>
      {/* <TransitionModel />
      <h1>Obesity Care Cascades</h1>
      #Descriptive />
      */}
    </Prose>
  </div>
)
