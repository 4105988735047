import React, { useRef, useEffect, useState, useInterval } from "react"
import * as d3 from "d3"

var n1x = 50
var n2x = 250
var n3x = 450
var n4x = 650

var nVy = 200

class Circle extends React.Component {
  render () {
    return (
      <svg>
        <defs>
          <marker
            id="arrow"
            refX="4"
            refY="2.5"
            markerWidth="5"
            markerHeight="5"
            orient="auto"
          >
            <path d="M0,0 L5,2.5 L0,5 Z" class="arrow"></path>
          </marker>
        </defs>
        <circle
          cx={this.props.cx}
          cy={this.props.cy}
          fill={this.props.fill}
          stroke={this.props.outline}
          r={this.props.r}
        />
        <text
          x={this.props.cx}
          y={this.props.cy + 5}
          class="small"
          text-anchor="middle"
        >
          {this.props.z}
        </text>
      </svg>
    )
  }
}

const LineSeg = (props) => {
    return (
      <svg>
        <path
          d={d3.line()([
            [props.x1 + 45, nVy],
            [props.x2 - 45, nVy],
          ])}
          stroke="black"
          fill="none"
          markerEnd={"url(#arrow)"}
        ></path>
      </svg>
    )
  }

const CurveSeg1 = props => {
  var lineGenerator = d3.line().curve(d3.curveCardinal)
  var points = [
    [n1x + 35, 170],
    [n1x + 50, 160],
    [n1x + 65, 150],
  ]
  var pathData = lineGenerator(points)
  return (
    <path
      d={pathData}
      stroke="black"
      stroke-dasharray="4"
      fill="none"
      markerEnd={"url(#arrow)"}
    ></path>
  )
}

const CurveSeg2 = props => {
  var lineGenerator = d3.line().curve(d3.curveCardinal)
  var points = [
    [n1x + 35 + 65 + 35, 150],
    [n1x + 50 + 65 + 35, 160],
    [n1x + 65 + 65 + 35, 170],
  ]
  var pathData = lineGenerator(points)
  return (
    <path
      d={pathData}
      stroke="black"
      stroke-dasharray="4"
      fill="none"
      markerEnd={"url(#arrow)"}
    ></path>
  )
}

const CurveSeg3 = props => {
  var lineGenerator = d3.line().curve(d3.curveCardinal)
  var points = [
    [n2x + 35, 170],
    [n2x + 50, 160],
    [n2x + 65, 150],
  ]
  var pathData = lineGenerator(points)
  return (
    <path
      d={pathData}
      stroke="black"
      stroke-dasharray="4"
      fill="none"
      markerEnd={"url(#arrow)"}
    ></path>
  )
}

const CurveSeg4 = props => {
  var lineGenerator = d3.line().curve(d3.curveCardinal)
  var points = [
    [n2x + 35 + 35 + 65, 150],
    [n2x + 50 + 35 + 65, 155],
    [n2x + 65 + 35 + 65 + 5, 165],
  ]
  var pathData = lineGenerator(points)
  return (
    <path
      d={pathData}
      stroke="black"
      stroke-dasharray="4"
      fill="none"
      markerEnd={"url(#arrow)"}
    ></path>
  )
}

const CurveSeg5 = props => {
  var lineGenerator = d3.line().curve(d3.curveCardinal)
  var points = [
    [n1x + 20, 160],
    [n2x - 0.5 * (n2x - n1x) + 20, 100],
    [n2x + 70, 130],
  ]
  var pathData = lineGenerator(points)
  return (
    <path
      d={pathData}
      stroke="black"
      stroke-dasharray="4"
      fill="none"
      markerEnd={"url(#arrow)"}
    ></path>
  )
}

const CurveSeg6 = props => {
  var lineGenerator = d3.line().curve(d3.curveCardinal)
  var points = [
    [n1x + 35, 230],
    [n3x - 0.5 * (n3x - n1x), 260],
    [n3x - 35, 230],
  ]
  var pathData = lineGenerator(points)
  return (
    <path
      d={pathData}
      stroke="black"
      stroke-dasharray="4"
      fill="none"
      markerEnd={"url(#arrow)"}
    ></path>
  )
}

const TransitionModel = () => {
  const nodes_v = [
    [n1x, nVy, "PCPv"],
    [n2x, nVy, "Ev"],
    [n3x, nVy, "WLSv"],
    [n4x, nVy, "OR"],
  ]

  const nodes_r = [
    [n2x - 0.5 * (n2x - n1x), nVy - 50, "Er"],
    [n3x - 0.5 * (n3x - n2x), nVy - 50, "WLSr"],
  ]

  const links = [
    [n1x, n2x],
    [n2x, n3x],
    [n3x, n4x]
  ]
  return (
    <div>
      <svg width="700px" height="400px">
        {nodes_v.map(([x, y, z], i) => (
          <Circle
            cx={x}
            cy={y}
            z={z}
            r="40"
            fill="white"
            outline="black"
            color="black"
          />
        ))}
        {nodes_r.map(([x, y, z], i) => (
          <Circle
            cx={x}
            cy={y}
            z={z}
            r="30"
            fill="white"
            outline="black"
            color="black"
          />
        ))}
        {links.map(([x1, x2], i) => (
          <LineSeg x1={x1} x2={x2} />
        ))}
        <CurveSeg1 />
        <CurveSeg2 />
        <CurveSeg3 />
        <CurveSeg4 />
        <CurveSeg5 />
        <CurveSeg6 />
      </svg>
      <p><b>KEY</b>:</p>
      <p><b>PCPv</b>: Primary care provider visit</p>
      <p><b>Er</b>: Endocrinology referral</p>
      <p><b>Ev</b>: Endocrinology visit</p>
      <p><b>WLSr</b>: Weight loss surgery referral</p>
      <p><b>WLSv</b>: Weight loss surgery visit</p>
      <p><b>OR</b>: Operating room - weight loss surgery procedure</p>
    </div>
  )
}

export default TransitionModel;