import tw from "tailwind.macro"
import React from "react"
import {Box, Label, Select } from '@rebass/forms'

//TODO render posts via graphql
import Descriptive from "./projects/descriptive"
import NodeOne from "./projects/nodeOne"
import NodeTwo from "./projects/nodeTwo"

import { Link } from "gatsby"

const countries = [
  {
    name: "1. descriptive analysis",
    content: <Descriptive />,
    selected: true,
    id: 0,
  },
  {
    name: "2. primary care referrals",
    content: <NodeOne />,
    selected: false,
    id: 1,
  },
  {
    name: "3. primary referral conversions",
    content: <NodeTwo />,
    selected: false,
    id: 2,
  },
]

const HeadMain = tw.div`
  p-2 bg-gray-100 rounded-lg shadow-2xl
`

const Text = tw.p`
  text-sm text-black-700
`

const Wrapper = tw.div`
  flex items-center justify-start flex-col h-screen
`

const Main = tw.div`
  p-12 bg-white w-screen rounded-lg
`

class Projects extends React.Component {
  constructor(props) {
    console.log(countries)
    super(props)
    this.state = { countries, value: "" }

    this.handleChange = this.handleChange.bind(this)
    this.handleClickMenu = this.handleClickMenu.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.toggleSelected = this.toggleSelected.bind(this)

  }

  handleChange(event) {
    this.setState({ value: event.target.value })
  }

  handleClickMenu(event) {
    console.log("Menu clicked")
    console.log(this.state)
    event.preventDefault()
  }
  handleInput(event) {
    console.log("A name was submitted: ")
    event.preventDefault()
  }

  toggleSelected(event) {
    let y = event.target.options.selectedIndex;
    //this.setState({ countries: countries[event.target.options.selectedIndex] })
    const newItems = [...this.state.countries]
    newItems.map(x => {
      x.selected = false
      return x
    })
    newItems[y].selected = true
    this.setState({ countries: newItems })
    console.log(this.state.countries)
    
  }

  render() {
    return (
      <Wrapper>
        <Main>
          <Link to="/">
            <Text>home</Text>
          </Link>
          <HeadMain>
            <Select
              id="country"
              name="country"
              defaultValue="United States"
              onClick={this.handleClickMenu}
              onInput={this.handleInput}
              onInput={this.handleChange}
              onInput={this.toggleSelected}
            >
              {Object.entries(this.state.countries).map(([key, country]) => (
                <option key={key} country={country.id}>
                  {country.name}
                </option>
              ))}
            </Select>
          </HeadMain>
          {this.state.countries.map(country => (
            country.selected ? country.content: " "
          ))}
        </Main>
      </Wrapper>
    )
  }
};

export default Projects;

