import React from "react"
import Prose from "../../components/prose"

export default () => (
      <Prose>
        <h1>second post</h1>
        <h2>its the second post post</h2>
        <h3> it needs no explanation</h3>
        <p>its selected</p>
      </Prose>
)
